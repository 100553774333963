import React, { useState, useEffect } from 'react'
import withSizes from 'react-sizes'
import styled from 'styled-components'

const desktopImage =
	'https://images.unsplash.com/photo-1551034261-3bd672e0a970?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80'
const mobileImage =
	'https://images.unsplash.com/photo-1551033453-3d94e6c88561?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80'

const StyledApp = styled.div`
	${tw`h-screen bg-cover bg-no-repeat bg-top bg-center`};
	@media (min-width: 650px) {
		${tw`bg-center bg-center`};
	}
`

const StyledAppContent = styled.div`
	${tw`h-full flex flex-col justify-start items-center p-8`};
	@media (min-width: 650px) {
		${tw`items-end justify-center`};
	}
`

const App = ({ isMobile }) => {
	const imageUrl = isMobile ? mobileImage : desktopImage

	return (
		<StyledApp style={{ backgroundImage: `url(${imageUrl})` }}>
			<StyledAppContent>
				<div>{isMobile ? 'Is Mobile' : 'Is Not Mobile'}</div>

				<h1>Pineapples</h1>
				<p>They are good</p>
			</StyledAppContent>
		</StyledApp>
	)
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 480
})

export default withSizes(mapSizesToProps)(App)

// const handleWindowResize = () => {
// 	setWindowWidth(window.innerWidth)
// }

// useEffect(() => {
// 	window.addEventListener('resize', handleWindowResize)

// 	return () => {
// 		window.removeEventListener('resize', handleWindowResize)
// 	}
// })

// const [windowWidth, setWindowWidth] = useState(window.innerWidth)
